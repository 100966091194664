import React from 'react';

const NotFoundPage = () => (
  <div className="text-center">
    <h1 className="text-2xl font-semibold">NOT FOUND</h1>
    <p className="mt-5">You just hit a route that doesn't exist... the sadness.</p>
  </div>
);

export default NotFoundPage;
